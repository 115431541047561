* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.Test {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  padding: 30px;
  width: 100vw;
  border-radius: 16px;
  margin: auto auto;
  background-color: white;
  align-self: center;
  z-index: 200;
}

.Test .close {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 30px;
  right: 30px;
}

@media screen and (max-width: 1280px) {
  .Test {
    height: 100vh;
    width: 100vw;
  }
}

.Form {
  background-color: white;
  width: 80vw;
  height: 85vh !important;
  margin: auto auto;
  align-self: center;
  border-radius: 16px;
}

.Form .close {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 30px;
  right: 30px;
}

.Form .close:hover {
  cursor: pointer;
}

.Test .close:hover {
  cursor: pointer;
}

.Test div {
  display: flex;
}

.Test h3 {
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  width: 80%;
  margin-bottom: 16px;
}

@media screen and (max-width: 980px) {
  .Test .qTitle {
    font-size: 18px !important;
  }
  .Test .qAns .qText {
    font-size: 12px !important;
  }
  .Test {
    overflow: scroll;
    padding: 30px 30px 100px 30px !important;
  }
  .Test.Test4 {
    padding: 30px!important;
  }
  .Test .btnNext,
  .btnPrev {
    position: fixed !important;
  }
  .Test .btnNext {
    bottom: 40px !important;
    right: 40px !important;
  }
  .Test .btnPrev {
    bottom: 40px !important;
    left: 40px !important;
  }
  .Test h3 {
    width: 70% !important;
    font-size: 12px !important;
  }
}

.Test > h3 {
  margin-bottom: 32px !important;
}

.Test > h3 {
  margin-left: 30px;
}

.Test .papersIcon {
  width: 17px;
  height: 20px;
  display: inline;
  margin-right: 10px;
}

.Test > h4 {
  font-size: 16px;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  width: 50%;
  position: absolute;
  top: 100px;
  right: 30px;
  text-align: end;
}

.Test .progress {
  min-height: 5px;
  background-color: #d88755;
  margin-bottom: 32px;
}

.Test .qTitle {
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  font-size: 26px;
  color: #464646;
  margin-bottom: 16px;
}

.Test .qTitle span {
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: red;
  margin-bottom: 16px;
  margin-left: 24px;
}

.Test .qAns {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 12px;
}

.Test .qAns:hover {
  cursor: pointer;
}

.Test .qAns .qSquare {
  min-width: 15px;
  min-height: 15px;
}

.Test .qAns .qText {
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: #464646;
  margin-bottom: 0px !important;
}

.Test .qAns .qText:hover {
  cursor: pointer;
}

.Test .btnPrev {
  width: 120px;
  height: 45px;
  background-color: #cbcbcb;
  outline: none;
  border: none;
  display: flex;
  position: absolute;
  bottom: 30px;
  left: 30px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.Test .btnPrev h6 {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: white;
  margin-bottom: 0px !important;
}

.Test .btnPrev img {
  width: 20px;
  transform: rotate(180deg);
}

.Test .btnNext {
  width: 120px;
  height: 45px;
  background-color: rgb(0, 92, 93);
  outline: none;
  border: none;
  display: flex;
  position: absolute;
  bottom: 30px;
  right: 30px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.Test .btnNext,
.btnPrev {
  transition: all 0.3s;
}

.Test .btnNext:hover,
.btnPrev:hover {
  cursor: pointer;
  background-color: #d88755;
}

.Test .btnNext h6 {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: white;
  margin-bottom: 0px !important;
}

.Test .btnNext img {
  width: 20px;
}

.Form {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

@media screen and (max-width: 1280px) {
  .Form {
    width: 90vw !important;
  }
  .Form .form1Prevbtn {
    bottom: 20px !important;
    left: 20px !important;
    font-size: 12px !important;
    width: 110px !important;
  }
  .Form .form1Prevbtn h6 {
    margin-bottom: 0px !important;
  }
  .Form input[type="submit"] {
    right: 20px !important;
    bottom: 20px !important;
    font-size: 12px !important;
    width: 150px !important;
  }
}

.Form .form1Prevbtn {
  width: 120px;
  height: 45px;
  background-color: #cbcbcb;
  outline: none;
  border: none;
  display: flex;
  position: absolute;
  bottom: 50px;
  left: 50px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  transition: all 0.4s;
}

.Form .form1Prevbtn h6 {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: white;
  margin-bottom: 0px !important;
}

.Form .form1Prevbtn img {
  width: 20px;
  transform: rotate(180deg);
}

.Form .form1Prevbtn:hover {
  cursor: pointer;
  background-color: #d88755;
}

.Form .line {
  width: 100%;
  height: 5px;
  background-color: #d88755;
}

.Form h3 {
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  color: #333333;
  font-weight: 500;
  margin: 35px 45px;
}

.Form form {
  padding: 35px 45px;
}

.Form input[type="submit"] {
  width: 190px;
  height: 45px;
  background-color: rgb(0, 92, 93);
  outline: none;
  border: none;
  display: flex;
  position: absolute;
  bottom: 50px;
  right: 50px;
  justify-content: center;
  align-items: center;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: white;
  transition: all 0.4s;
}

.Form input[type="submit"]:hover {
  background-color: #d88755;
  cursor: pointer;
}

.Form input[type="text"] {
  border: none;
  border-bottom: 1px solid #7f7d7c;
  padding-bottom: 12px;
  outline: none;
  display: block;
  width: 100%;
  margin-bottom: 32px;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: black;
}

.Form input[type="email"] {
  border: none;
  border-bottom: 1px solid #7f7d7c;
  padding-bottom: 12px;
  outline: none;
  display: block;
  width: 100%;
  margin-bottom: 32px;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: black;
}

.Form input[type="email"]:invalid {
  border-bottom: 1px solid red;
}

.Test4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 200;;
}

.Test4 h1 {
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 20px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  width: 80%;
  margin-bottom: 16px;
}

.Test4 h1::before {
  content: "";
  display: inline-block;
  width: 16px; /* Ширина иконки */
  height: 20px; /* Высота иконки */
  background-image: url('../assets/icons/papersIcon.png'); /* Адрес картинки относительно CSS файла */
  background-size: cover;
  margin-right: 16px; /* Отступ справа от иконки до заголовка */
}


.Test4 .test4Colors1 {
  display: flex;
  height: 50%;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (min-width: 1200px) {
  .Test4 .test4Colors1 {
    gap: 0.55%;
  }
}

.Test4 .test4Colors1 .block {
  width: 12%;
  height: 45%;
  border-radius: 8px;
  flex-grow: 1;
}

@media screen and (max-width: 1200px) {
  .Test4 .test4Colors1 {
    gap: 20px 1%;
    height: auto !important;
  }
  .Test4 .test4Colors1 .block {
    width: 49% !important;
    height: 50px !important;
  }
}

.Test4 .test4Colors1 .block:hover {
  cursor: pointer;
}

.Form4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Form4 h3 {
  font-family: "Manrope", sans-serif;
  font-size: 18px !important;
  color: #333333;
  font-weight: 500;
  width: 100% !important;
  margin-bottom: 36px;
}

.Form4 input {
  border: none;
  border-bottom: 1px solid #7f7d7c;
  padding-bottom: 12px;
  outline: none;
  width: 100%;
  margin-bottom: 32px;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: black;
}

.Form4 .line {
  width: 100%;
  height: 5px;
  background-color: #d88755;
  margin-bottom: 36px;
}

.Form4 form {
  width: 100%;
}

.Form4 input[type="email"]:invalid {
  border-bottom: 1px solid red;
}

.Form4 .form4Btn {
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  margin: 0 auto;
  background-color: #d88755;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  color: white;
}
