@font-face {
  font-family: "ApocIA";
  src: url("./assets/fonts/Apoc-Regular Italic-App.ttf") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "ApocIW";
  src: url("./assets/fonts/Apoc-Regular Italic-Web.ttf") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "ApocA";
  src: url("./assets/fonts/Apoc-Regular-App.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "ApocW";
  src: url("./assets/fonts/Apoc-Regular-Web.ttf") format("truetype");
  font-style: normal;
}

header {
  z-index: 100;
  height: 65px;
  background-color: #fefbf8;
  display: flex;
  padding: 0px 60px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.Form input[name="test1_result_number"] {
  display: none;
}

.Form input[name="test1_result_title"] {
  display: none;
}

.Form input[name="test1_result_text"] {
  display: none;
}

@media screen and (min-width: 1280px) {
  header {
    position: fixed;
    width: 100%;
  }
}

header img {
  width: 120px;
  height: 44px;
}

header a {
  font-size: 14px;
  text-decoration: none;
  font-family: "Manrope", sans-serif;
  color: #515151;
  transition: 0.5s;
  font-weight: 500;
}

header a:hover {
  color: #d88755;
}

header .leftLinks,
header .rightLinks {
  display: flex;
  gap: 50px;
}

@media screen and (min-width: 1280px) {
  header .burgerMenuIcon {
    display: none;
  }
}

@media screen and (max-width: 1280px) {
  header .leftLinks,
  header .rightLinks {
    display: none;
  }
}

header .burgerMenuIcon {
  height: 15px;
  width: 45px;
}

header .burgerMenuIcon:hover {
  cursor: pointer;
}

.App {
  background: url("./assets/imgs/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App .startblock {
  height: 100vh;
  background: url("./assets//imgs/bg.png");
  background-size: 120%;
  background-repeat: none;
  background-position: center calc(50% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 30px;
  animation: backgroundZoom 3s ease-in-out 1 forwards;
}

@media screen and (max-width: 1280px) {
  .App .startblock h2 span {
    font-size: 42px !important;
  }
}

@media screen and (max-width: 780px) {
  header .burgerMenuIcon {
    height: 30px !important;
  }
  .App .startblock h3 {
    font-size: 12px !important;
  }
  .App .startblock {
    height: 400px;
    background-position: center calc(50% - 150px);
    background-repeat: no-repeat;
  }
  .App .startblock h2 span {
    font-size: 24px !important;
  }
}

@keyframes backgroundZoom {
  0% {
    background-size: 100%;
  }
  100% {
    background-size: 150%;
  }
}

.App .startblock h3 {
  font-family: "Manrope", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  color: #fefbf8;
}

.App .startblock h2 {
  width: 90%;
}

.App .startblock h2 span {
  font-family: "ApocW";
  font-size: 64px;
  color: #fefbf8;
  font-weight: 400;
}

.App .startblock img {
  width: 230px;
  height: 84px;
  animation: fadeIn 6s ease forwards;
}

#letter1 {
  opacity: 0;
  animation: fadeIn 3.5s ease forwards;
}

#letter2 {
  opacity: 0;
  animation: fadeIn 4.2s ease forwards;
}

#letter3 {
  opacity: 0;
  animation: fadeIn 3.8s ease forwards;
}

#letter4 {
  opacity: 0;
  animation: fadeIn 4.3s ease forwards;
}

#letter5 {
  opacity: 0;
  animation: fadeIn 3.7s ease forwards;
}

#letter6 {
  opacity: 0;
  animation: fadeIn 2s ease forwards;
}

#letter7 {
  opacity: 0;
  animation: fadeIn 2.4s ease forwards;
}

#letter8 {
  opacity: 0;
  animation: fadeIn 4.6s ease forwards;
}

#letter9 {
  opacity: 0;
  animation: fadeIn 2.9s ease forwards;
}

#letter10 {
  opacity: 0;
  animation: fadeIn 3.1s ease forwards;
}

#letter11 {
  opacity: 0;
  animation: fadeIn 4.7s ease forwards;
}

#letter12 {
  opacity: 0;
  animation: fadeIn 3.8s ease forwards;
}

#letter13 {
  opacity: 0;
  animation: fadeIn 2.2s ease forwards;
}

#letter14 {
  opacity: 0;
  animation: fadeIn 3.6s ease forwards;
}

#letter15 {
  opacity: 0;
  animation: fadeIn 3.3s ease forwards;
}

#letter16 {
  opacity: 0;
  animation: fadeIn 4.9s ease forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.App .testTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fefbf8;
  height: 200px;
  text-align: center;
  line-height: 50px;
}

.App .testTitle h2 {
  font-family: "ApocW";
  color: #df854f;
  font-size: 55px;
  font-weight: 500;
  width: 80%;
}

.App .testTitle h2 span {
  font-family: "ApocIW";
  color: #df854f;
  font-size: 55px;
}

@media screen and (max-width: 1280px) {
  .App .testTitle {
    line-height: 32px !important;
  }
  .App .testTitle h2 {
    font-size: 34px !important;
  }
  .App .testTitle h2 span {
    font-size: 34px !important;
  }
}

@media screen and (max-width: 780px) {
  .App .testTitle {
    line-height: 20px !important;
    height: 100px !important;
  }
  .App .testTitle h2 {
    font-size: 18px !important;
  }
  .App .testTitle h2 span {
    font-size: 18px !important;
  }
}

.App .testsBlock {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  padding-bottom: 100px;
  background-color: white;
  width: 100%;
}

.App .testsBlock .row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0px;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.3);
  width: 90%;
}

@media screen and (max-width: 1280px) {
  .App .testsBlock .row img {
    margin-bottom: 16px !important;
    width: 120px !important;
    height: 100px !important;
  }
  .App .testsBlock .row {
    flex-direction: column;
    align-items: center;
  }
  .App .testsBlock .row h3 {
    text-align: center;
    width: 100%;
    margin-bottom: 16px;
  }
  .App .testsBlock .row .col {
    width: 100% !important;
    text-align: center;
    gap: 24px !important;
    align-items: center !important;
  }
  .addTextResultsToMail {
    width: 100% !important;
    text-align: left !important;
    padding: 24px !important;
  }
  header {
    padding: 0px 20px !important;
  }
}

.App .testsBlock .row img {
  width: 150px;
}

.App .testsBlock .row .col {
  width: 40%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  height: 100%;
  gap: 12px;
}

.App .testsBlock .row .col h4,
button {
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}

.App .testsBlock .row .col button {
  width: 160px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e08f8a;
  font-family: "Manrope", sans-serif;
  border: none;
  color: #f2ede7;
  transition: all 0.5s;
}

.App .testsBlock .row .col a {
  text-decoration: none;
}

.App .testsBlock .row .col button:hover {
  background-color: #df854f;
  cursor: pointer;
}

.App .testsBlock .row h3 {
  align-self: flex-start;
  font-size: 30px;
  font-family: "ApocIW";
  font-weight: 400;
}

@media screen and (min-width: 1280px) {
  .App .testsBlock .row h3 {
    text-align: left !important;
    width: 30%;
  }
}

.addTextResultsToMail {
  width: 100%;
  padding: 30px 100px;
  display: flex;
  justify-content: flex-end;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  color: #333333;
  font-weight: 400;
}

.mobileMenu {
  display: flex;
  flex-direction: column;
}

.mobileMenu img {
  width: 200px;
  margin-bottom: 20px;
}

.mobileMenu .mobileMenuBody {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.mobileMenu .mobileMenuBody a {
  font-family: "Manrope", sans-serif;
  text-decoration: none;
  font-size: 24px;
  font-weight: 500;
  color: #333333;
}

.mobileMenu .mobileMenuTitle {
  font-family: "Manrope", sans-serif;
  text-decoration: none;
  font-size: 36px;
  font-weight: 600;
  color: #d88755;
}

.App .shadowBlock {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.App .closeBtnPopup {
  position: fixed;
  top: 89px;
  right: 24px;
  z-index: 2;
  font-family: "Manrope", sans-serif;
  color: white;
  font-weight: 700;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .App .closeBtnPopup {
    z-index: 200;
    top: 30px;
  }
}