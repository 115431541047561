.Test10 {
    background: transparent;
    background-size: cover;
    height: 100vh;
    width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: showTest 0.5s 1;
    z-index: 100;
}

@keyframes showTest {
    0% {
        opacity: 0;
    } 
    100% {
        opacity: 1;
    }
}

.Test10 .block {
    width: 100%;
    height: 500px;
    background-color: white;
    border-radius: 16px;
    box-sizing: border-box;
    padding: 24px;
    position: relative;
}

.Test10 .block .progress {
    background-color: #df854f;
    height: 5px;
    border-radius: 16px;
    margin-bottom: 16px;
}

.Test10 .block h2 {
    font-size: 20px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    margin-bottom: 32px;
    height: 60px;
}

.Test10 .block h4 {
    font-size: 20px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
}

.Test10 .block h2 span {
    font-size: 20px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
}

.Test10 .block button.prev {
    position: absolute;
    width: 90px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.33);
    text-align: center;
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
}

.Test10 .block button.next {
    position: absolute;
    width: 90px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.33);
    text-align: center;
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
}

.Test10 .block button.close {
    border: none;
    background: none;
    position: absolute;
    top: 48px;
    right: 24px;
}

.Test10 .block h6 {
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    margin-bottom: 0px;
}

.Test10 .block button.prev {
    bottom: 24px;
    left: 24px;
}

.Test10 .block button.next {
    bottom: 24px;
    right: 24px;
}

.Test10 .block button.active {
    background-color: #df854f;
    color: white;
    border: none;
}

.Test10 .block .q1 {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.Test10 .block .q1 button {
    outline: none;
    border: 1px solid black;
    background-color: white;
    color: black;
    transition: all 0.2s;
    padding: 8px 0px;
    text-align: center;
    font-size: 16px;
    font-family: "Manrope", sans-serif;
}

.Test10 .block .q1 button.active {
    background-color: #df854f;
    border: 1px solid transparent;
    color: white;
}

.Test10 .q2 {
    display: flex;
    gap: 16px 16px;
    flex-wrap: wrap;
}

.Test10 .q2 img {
    cursor: pointer;
    transition: all 0.3s;
}

.Test10 .q2 img:hover {
    filter: brightness(0.75);
}

.Test10 .q3 {
    display: flex;
    gap: 16px 16px;
    flex-wrap: wrap;
}

.Test10 .q3 img {
    cursor: pointer;
    transition: all 0.3s;
}

.Test10 .q3 img:hover {
    filter: brightness(0.75);
}

@media screen and (max-width: 900px) {
    .Test10 .block {
        width: 90%;
        height: 90%;
    }
    .Test10 .block .choose-reversed {
        flex-direction: column;
        width: 100%;
    }
    .Test10 .block .choose {
        flex-direction: column;
        width: 100%;
    }
    .Test10 .block h2 {
        height: auto;
    }
}

.Test10 .block .q h3 {
    font-size: 24px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
}

.Test10 .block .q img {
    width: 100px;
    height: 100px;
    margin-bottom: 16px;
}

.Test10 .block .q16 {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 16px;
}

.Test10 .block .q16 img {
    cursor: pointer;
    transition: all 0.2s;
}

.Test10 .block .q16 img:hover {
    transform: scale(1.1);
}

.Test10 .block .q input {
    height: 40px;
    padding: 16px;
    border: 2px solid #df854f;
    font-size: 20px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    width: 300px;
    box-sizing: border-box;
}

.Test10 .block .q input:focus {
    border: 2px solid #df854f;
    outline: none;
}

.Test10 .block .q input::placeholder {
    font-size: 14px;
}

@media screen and (max-width: 900px) {
    .Test10 {
        width: 90vw;
        height: 500px;
    }
    .Test10 .block {
        overflow: scroll;
    }
    .Test10 .block h2 {
        height: auto;
    }
    .Test10 .block .q input {
        width: 100%;
    }
    .Test10 .block .q16 img {
        height: 50px!important;
    }
}