.Test11 {
    background: transparent;
    background-size: cover;
    height: 100vh;
    width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: showTest 0.5s 1;
    z-index: 100;
}

@keyframes showTest {
    0% {
        opacity: 0;
    } 
    100% {
        opacity: 1;
    }
}

.Test11 .block {
    width: 100%;
    height: 500px;
    background-color: white;
    border-radius: 16px;
    box-sizing: border-box;
    padding: 24px;
    position: relative;
    box-shadow: 0px 0px 3px 3px #00000012;
}

.Test11 .block .progress {
    background-color: #df854f;
    height: 5px;
    border-radius: 16px;
    margin-bottom: 16px;
}

.Test11 .block h2 {
    font-size: 20px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    margin-bottom: 32px;
    height: 60px;
}

.Test11 .block h4 {
    font-size: 20px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
}

.Test11 .block h2 span {
    font-size: 20px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
}

.Test11 .block button.prev {
    position: absolute;
    width: 90px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.33);
    text-align: center;
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
}

.Test11 .block button.next {
    position: absolute;
    width: 90px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.33);
    text-align: center;
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
}

.Test11 .block button.close {
    border: none;
    background: none;
    position: absolute;
    top: 48px;
    right: 24px;
}

.Test11 .block h6 {
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    margin-bottom: 0px;
}

.Test11 .block button.prev {
    bottom: 24px;
    left: 24px;
}

.Test11 .block button.next {
    bottom: 24px;
    right: 24px;
}

.Test11 .block button.active {
    background-color: #df854f;
    color: white;
    border: none;
}

.Test11 .block .q1 {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.Test11 .block .q1 button {
    outline: none;
    border: 1px solid black;
    background-color: white;
    color: black;
    transition: all 0.2s;
    padding: 8px 0px;
    text-align: center;
    font-size: 16px;
    font-family: "Manrope", sans-serif;
}

.Test11 .block .q1 button.active {
    background-color: #df854f;
    border: 1px solid transparent;
    color: white;
}

.Test11 .q2 {
    display: flex;
    gap: 16px 16px;
    flex-wrap: wrap;
}

.Test11 .q2 img {
    cursor: pointer;
    transition: all 0.3s;
}

.Test11 .q2 img:hover {
    filter: brightness(0.75);
}

.Test11 .q3 {
    display: flex;
    gap: 16px 16px;
    flex-wrap: wrap;
}

.Test11 .q3 img {
    cursor: pointer;
    transition: all 0.3s;
}

.Test11 .q3 img:hover {
    filter: brightness(0.75);
}

@media screen and (max-width: 900px) {
    .Test11 {
        width: 90vw;
    }
    .Test11 .block {
        width: 90vw;
    }
    .Test11 .block .choose-reversed {
        flex-direction: column;
        width: 100%;
    }
    .Test11 .block .choose {
        flex-direction: column;
        width: 100%;
    }
    .Test11 .block h2 {
        height: auto;
        width: 90%;
    }
    .Test11 .q2 img {
        width: 100px;
    }
    .Test11 .q3 img {
        width: 100px;
    }
}